import React, { useState, useContext, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { InflowContext } from '../../stores/InflowContext';
import { UserContext } from '../../stores/UserContext';

import { strOnlyTwoDecimals, BRLCurrency, makePdfColumn } from '../../helpers';

import './ResumeTransactions.css';

pdfMake.addVirtualFileSystem(pdfFonts);

const today = new Date();
const date = today.toLocaleDateString('pt-BR');

const inflowTypeDefault = {
  Dízimos: 0,
  Ofertas: 0,
  Construção: 0,
};

const inflowMethodDefault = {
  Cédulas: 0,
  Moedas: '',
  Pix: 0,
};

function ResumeTransactions() {
  const { resumeInflowTransactions } = useContext(InflowContext);
  const { appLocation } = useContext(UserContext);

  const [emptyResumeTransactions, setEmptyResTrans] = useState(
    Object.keys(resumeInflowTransactions).length === 0,
  );
  const [inflowType, setInflowType] = useState(inflowTypeDefault);
  const [inflowMethod, setInflowMethod] = useState(inflowMethodDefault);

  useEffect(() => {
    setEmptyResTrans(Object.keys(resumeInflowTransactions).length === 0);

    if (!emptyResumeTransactions) {
      const inflowTypeNewValue = {
        Dízimos: resumeInflowTransactions.Dízimos,
        Ofertas: resumeInflowTransactions.Ofertas,
        Construção: resumeInflowTransactions.Construção,
      };

      const inflowMethodNewValue = {
        Cédulas: resumeInflowTransactions.Dinheiro,
        Moedas: '',
        Pix: resumeInflowTransactions.Pix,
      };

      setInflowType(inflowTypeNewValue);
      setInflowMethod(inflowMethodNewValue);
      setEmptyResTrans(false);
    }
  }, [emptyResumeTransactions, resumeInflowTransactions]);

  const exportPdf = (e) => {
    e.preventDefault();

    const totalTransactions = { Total: resumeInflowTransactions.Total };

    const inflowTypePdfColumn = Object.entries(inflowType).map(makePdfColumn);
    const inflowMethodPdfColumn = Object.entries(inflowMethod).map(makePdfColumn);
    const totalTransactionsPdfColumn = Object.entries(totalTransactions).map(makePdfColumn);

    const docDefinition = {
      pageSize: {
        width: 450,
        height: 'auto',
      },
      pageMargins: [25, 0],
      content: [
        { text: '-----------------------------------------', alignment: 'center' },
        {
          text: `Resumo ${date}`, margin: [0, 0, 0, 40], alignment: 'center', bold: true,
        },
        inflowTypePdfColumn,
        { text: '----------------', alignment: 'center' },
        inflowMethodPdfColumn,
        { text: '----------------', alignment: 'center' },
        totalTransactionsPdfColumn,
        { text: '-----------------------------------------', margin: [0, 160, 0, 0], alignment: 'center' },
      ],
      defaultStyle: {
        fontSize: 30,
      },
    };

    const pdfFileName = `imw-entradas-${date.replaceAll('/', '-')}.pdf`;
    pdfMake.createPdf(docDefinition).download(pdfFileName);
  };

  const changeCedulaValue = (e) => {
    const { value } = e.target;

    const amountStr = strOnlyTwoDecimals(value);
    const amountFloat = amountStr ? parseFloat(amountStr) : 0;

    const newNoteValue = resumeInflowTransactions.Dinheiro - amountFloat;

    if (newNoteValue < 0) return;

    setInflowMethod((prevInflowMethod) => ({
      ...prevInflowMethod,
      Moedas: amountStr,
      Cédulas: Math.round(newNoteValue * 100) / 100,
    }));
  };

  const resumeTransactionsInfo = ([tittle, amount]) => {
    if (tittle === 'Moedas') {
      return (
        <label key={tittle} htmlFor="resume_moedas">
          <span>{`${tittle}: R$ `}</span>
          <input
            id="resume_moedas"
            type="number"
            step="0.01"
            min="0"
            max="999999.99"
            name="Moedas"
            value={inflowMethod.Moedas}
            onChange={changeCedulaValue}
            placeholder="0.00"
          />
        </label>
      );
    }
    return (
      <span key={tittle}>
        {`${tittle}: `}
        {BRLCurrency(amount)}
      </span>
    );
  };

  if (emptyResumeTransactions) return (<div />);

  return (
    <form onSubmit={exportPdf} className={`component-default-container resume-transactions-container ${appLocation}-container`}>
      <div className="resume-transactions-tittle">
        <h3>
          {'Resumo '}
          {date}
        </h3>
        <h3>
          {'Total: '}
          { BRLCurrency(resumeInflowTransactions.Total) }
        </h3>
      </div>
      <div className="resume-transactions-left">
        { Object.entries(inflowType).map(resumeTransactionsInfo)}
      </div>
      <div className="resume-transactions-right">
        { Object.entries(inflowMethod).map(resumeTransactionsInfo)}
      </div>
      <button type="submit">Imprimir resumo</button>
    </form>
  );
}

export default ResumeTransactions;
