import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../stores/UserContext';
import { InflowContext } from '../stores/InflowContext';

import UserProfile from '../components/UserProfile';
import ChangePage from '../components/ChangePage';
import TransactionForm from '../components/TransactionForm';
import ResumeTransactions from '../components/ResumeTransactions';
import TransactionsTable from '../components/TransactionsTable';
import TotalsMonthly from '../components/TotalsMonthly';

import sendLocationToAnalytics from '../services/apiRequests';
import entradasTransactionCodes from '../helpers/codigos_pae/entradas';

function InflowPage() {
  const {
    inflowTransactions,
    inflowDescriptionSuggestions,
    createInflowTransaction,
    updateInflowTransaction,
    deleteInflowTransaction,
  } = useContext(InflowContext);
  const { userLogged } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    sendLocationToAnalytics(document.title, window.location.href);
  }, []);

  useEffect(() => {
    if (userLogged) {
      return navigate('/entradas');
    }
    return navigate('/');
  }, [userLogged]);

  return (
    <>
      <UserProfile />
      <TotalsMonthly
        inflowTransactions={inflowTransactions}
      />
      <ChangePage />
      <TransactionForm
        transactionCodes={entradasTransactionCodes}
        transactionCodeDefault={Object.keys(entradasTransactionCodes)[0]}
        updateTransaction={updateInflowTransaction}
        descriptionSuggestions={inflowDescriptionSuggestions}
        createTransaction={createInflowTransaction}
      />
      <ResumeTransactions />
      <TransactionsTable
        transactions={inflowTransactions}
        updateTransaction={updateInflowTransaction}
        removeTransaction={deleteInflowTransaction}
      />
    </>
  );
}

export default InflowPage;
