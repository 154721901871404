import axios from 'axios';

const sendLocationToAnalytics = async (pageTitle, location) => {
  try {
    const headers = {
      keyword: process.env.REACT_APP_BRITO_VISITORS_ANALYTICS_KEYWORD,
    };
    const data = {
      origin: location,
      pageTitle,
    };
    const url = process.env.REACT_APP_BRITO_VISITORS_ANALYTICS_URL;

    await axios.post(url, data, { headers });
  } catch (error) {
    console.error(error);
  }
};

export default sendLocationToAnalytics;
